import React, { createContext, useContext, useState, useEffect } from 'react';
import Axios from 'axios';

const BranchContext = createContext();

export const BranchProvider = ({ children }) => {
  const [typeGroup, setTypeGroup] = useState([]);
  const [companyGroup, setCompanyGroup] = useState([]);
  const [branchGroup, setBranchGroup] = useState([]);
  const [type, setContextType] = useState('');
  const [company, setContextCompany] = useState('');
  /******************************************************/
  /* 지점종류 > 회사이름 > 지점이름 순차검색 */
  /******************************************************/
  useEffect(() => {
    getType();
  }, []);

  useEffect(() => {
    if (type !== '') {
      getCompany();
    }
  }, [type]);

  useEffect(() => {
    if (company !== '') {
      getBranch();
    }
  }, [company]);
  /******************************************************/
  /* type_total 테이블 지점종류 호출 */
  /******************************************************/
  const getType = async () => {
    try {
      const response = await Axios.get('https://www.wlcmanager.com:8443/api/get/type');
      setTypeGroup(response.data); // 지점종류
    } catch (error) {
      console.error('지점종류 호출실패:', error);
    }
  };
  /******************************************************/
  /* type_total 테이블 getType에서 가져온 데이터와 일치하는 데이터행의 company_name 호출 */
  /******************************************************/
  const getCompany = async () => {
    try {
      const response = await Axios.get(`https://www.wlcmanager.com:8443/api/get/company/${type}`);
      setCompanyGroup(response.data); // 회사이름
    } catch (error) {
      console.error('지점종류에 맞는 회사이름 호출실패:', error);
    }
  };
  /******************************************************/
  /* type_total 테이블 getCompany에서 가져온 데이터와 일치하는 데이터행의 branch_name 호출 */
  /******************************************************/
  const getBranch = async () => {
    try {
      const response = await Axios.get(`https://www.wlcmanager.com:8443/api/get/branchcate/${company}`);
      setBranchGroup(response.data); // 지점이름
    } catch (error) {
      console.error('Error fetching branch list:', error);
    }
  };
  /******************************************************/
  return (
    <BranchContext.Provider
      value={{
        typeGroup,
        companyGroup,
        branchGroup,
        getType,
        getCompany,
        getBranch,
        setContextType,
        setContextCompany,
      }}
    >
      {children}
    </BranchContext.Provider>
  );
};

/******************************************************/
//다른 컴포넌트에서 사용할 useContext(이름변경가능)
/******************************************************/
export const useBranchContext = () => {
  return useContext(BranchContext);
};
/******************************************************/

import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import TableDefault from '../Table/TableDefault';
import Axios, { all } from 'axios';
import moment from 'moment';
import ProductDetailModal from '../Modal/Grade_3/ProductDetail/ProductDetailModal';
import LinkPhoneModal from '../Modal/Grade_3/SendLink/LinkPhoneModal';
import SevendaysViewModal from '../Modal/Grade_3/WithinSevendays/SevendaysViewModal';
import Tel from '../Common/Tel';

const Home = () => {
  const { decodeS0, decodeS1, decodeS2, decodeS3, decodeS4, decodeS5 } = useAuth();
  const [homeData, setHomeData] = useState([]); // 병원 리스트
  const [totalData, setTotalData] = useState([]); // 총지점수
  const [managerData, setManagerData] = useState([]); // 총영업자수
  const [contractData, setContractData] = useState([]); // 총예약자수
  const [hopeData, setHopeData] = useState([]); // 상담고객수
  const [customerData, setCustomerData] = useState([]); // 총고객수
  const [totalCost, setTotalCost] = useState(''); //총매출
  const [totalPrice, setTotalPrice] = useState(''); //순이익
  const [viewModal, setViewModal] = useState(false);
  const [detailData, setDetailData] = useState([]);
  const [paidCommision, setPaidCommision] = useState([]); //총 지급커미션합계
  const [UnPaidCommision, setUnPaidCommision] = useState([]); //총 미지급커미션합계

  const [hospitalTotalData, setHospitalTotalData] = useState(0); // 총 고객수
  const [hospitalCompletedData, setHospitalCompletedData] = useState(0); // 검진 완료 고객수
  const [hospitalCanceledData, setHospitalCanceledData] = useState(0); // 검진 취소 고객수
  const [hospitalPendingData, setHospitalPendingData] = useState(0); // 검진 대기 고객수
  const [hospitalInsuranceCheckData, setHospitalInsuranceCheckData] = useState(0); // 보험 점검 고객수

  const [grade2Data, setGrade2Data] = useState([]); //지점장 페이지데이터
  const [grade2Data2, setGrade2Data2] = useState([]);
  const [grade2Data3, setGrade2Data3] = useState([]);
  const [grade2Data4, setGrade2Data4] = useState([]);
  const [grade2Data5, setGrade2Data5] = useState([]);
  const [productData, setProductData] = useState([]);
  const [productModal, setProductModal] = useState(false);
  const [selectProduct, setSelectProduct] = useState([]);
  const link = `https://www.wlcmanager.com/self/${decodeS1()}`;
  const [modal, setModal] = useState(false);

  const navigation = useNavigate();

  useEffect(() => {
    // Kakao SDK 초기화
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init('fe9383073f8c19983e8f9ab187b0ba07');
    }
  }, []);

  useEffect(() => {
    getTotalData();
    grade2TotalData();
    fetchData();
    getProductData();
    getHospitalCustomerTotalData();
  }, [decodeS1()]);

  //카카오톡 메세지 전송 API (링크전송목적)
  const sendKakaoMessage = () => {
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: `메디코드 ${decodeS2()}님의 링크공유`,
        description: `건강검진 예약을 신청하세요.`,
        imageUrl: '', // 이미지 URL 설정 가능
        link: {
          webUrl: link, // 웹에서의 링크 URL 설정
          mobileWebUrl: link, // 모바일에서의 링크 URL 설정
        },
      },
      buttons: [
        {
          title: '검진예약',
          link: {
            webUrl: link, // 웹에서의 버튼 클릭 시 이동할 URL 설정
            mobileWebUrl: link, // 모바일에서의 버튼 클릭 시 이동할 URL 설정
          },
        },
      ],
    });
  };

  const handleCopyLink = () => {
    const textToCopy = `https://www.wlcmanager.com/self/${decodeS1()}`;
    if (!navigator.clipboard) {
      // 클립보드 API가 지원되지 않는 경우
      alert('현재 브라우저에서는 이 기능을 지원하지 않습니다.');
      return;
    }
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alert('링크가 복사되었습니다.');
      })
      .catch((err) => {
        console.error('텍스트 복사 실패:', err);
      });
  };

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  // 슈퍼관리자 페이지
  const getTotalData = async () => {
    try {
      const response = await Axios.get('https://www.wlcmanager.com:8443/api/get/home_total');
      const allData = response.data.branchCount;
      const managerData = response.data.userCount;
      const contractData = response.data.contractCount;
      const hopeData = response.data.hopeCount;
      const customerData = response.data.customerCount;
      const resultData = response.data.result_date;
      const totalCost = response.data.totalCost;
      const totalPrice = response.data.totalPrice;
      const paidCommissionSumSql = response.data.totalPaidCommission;
      const unpaidCommissionSumSql = response.data.totalUnpaidCommission;

      setTotalData(allData);
      setManagerData(managerData);
      setContractData(contractData);
      setHopeData(hopeData);
      setCustomerData(customerData);
      setTotalCost(totalCost);
      setTotalPrice(totalPrice);
      setPaidCommision(paidCommissionSumSql);
      setUnPaidCommision(unpaidCommissionSumSql);
    } catch (error) {
      console.error('Error fetching list:', error);
    }
  };

  const getProductData = async () => {
    try {
      const response = await Axios.get('https://www.wlcmanager.com:8443/api/get/reserv/product_list');
      const allData = response.data.data;
      setProductData(allData);
    } catch (error) {
      console.log(error);
    }
  };

  //지점장 페이지
  const grade2TotalData = async () => {
    try {
      const response = await Axios.get('https://www.wlcmanager.com:8443/api/get/home_manager', {
        params: {
          branchIdx: decodeS0(),
        },
      });
      const gradeData = response.data.data;

      const branchAccount = gradeData.branchAccount;
      const userAccount = gradeData.userAccount;
      const hTotal = gradeData.hTotal;
      const hope = gradeData.hope;
      const contract = gradeData.contract;

      setGrade2Data(branchAccount);
      setGrade2Data2(userAccount);
      setGrade2Data3(hTotal);
      setGrade2Data4(hope);
      setGrade2Data5(contract);

      // console.log(gradeData);
    } catch (error) {
      console.error('Error fetching list:', error);
    }
  };

  // 영업사원 페이지
  const fetchData = async () => {
    try {
      const response = await Axios.get('https://www.wlcmanager.com:8443/api/get/home_list', {
        params: {
          uid: decodeS1(),
        },
      });

      const allData = response.data.data;

      // 오늘 날짜와 7일 전, 7일 후 날짜 계산
      const today = moment(); // 오늘 날짜
      const sevenDaysAgo = moment().subtract(7, 'days'); // 7일 전 날짜
      const sevenDaysLater = moment().add(7, 'days'); // 7일 후 날짜

      // 7일 이내 데이터 필터링 (과거와 미래 포함)
      const filteredData = allData.filter((data) => {
        const resultDate = moment(data.result_date, 'YYYY.MM.DD'); // `result_date`를 Moment.js 객체로 변환
        return resultDate.isBetween(sevenDaysAgo, sevenDaysLater, null, '[]'); // 범위 조건 확인
      });

      setHomeData(filteredData); // 필터링된 데이터를 상태로 설정
    } catch (error) {
      console.error('Error fetching list:', error);
    }
  };

  // 병원관리자 페이지
  const getHospitalCustomerTotalData = async () => {
    try {
      const response = await Axios.get('https://www.wlcmanager.com:8443/api/get/home_hospital_customer_total', {
        params: {
          uid: decodeS1(), // uid를 params로 추가
        },
      });

      const { totalCustomers, completedCustomers, canceledCustomers, pendingCustomers, insuranceCheckCustomers } =
        response.data;

      setHospitalTotalData(totalCustomers); // 총 고객수
      setHospitalCompletedData(completedCustomers); // 검진 완료 고객수
      setHospitalCanceledData(canceledCustomers); // 검진 취소 고객수
      setHospitalPendingData(pendingCustomers); // 검진 대기 고객수
      setHospitalInsuranceCheckData(insuranceCheckCustomers); // 보험 점검 고객수
    } catch (error) {
      console.error('Error fetching hospital customer data:', error);
    }
  };

  const columns = [
    { field: 'id', headerName: 'No', flex: 0.5 },
    { field: 'name', headerName: '예약자' },
    { field: 'customerName', headerName: '검진자' },
    { field: 'phone', headerName: '연락처' },
    { field: 'product', headerName: '상품' },
    { field: 'hospital', headerName: '검진병원' },
    { field: 'result_date', headerName: '검진확정일' },
  ];

  const columnsForMobile = [
    { field: 'customerName', headerName: '검진자' },
    { field: 'phone', headerName: '연락처' },
    { field: 'result_date', headerName: '검진확정일' },
  ];

  const rows = homeData.map((data, index) => ({
    id: index + 1,
    idx: data.idx,
    name: data.contractor_name,
    customerName: data.name,
    phone: data.phone,
    product: data.productName,
    hospital: data.hospitalName,
    result_date: data.result_date,
  }));

  const returnTotalCost = (num) => {
    const number = Number(num); // 숫자 이외의 문자 제거 후 숫자로 변환
    if (number > 0) {
      return number.toLocaleString(); // 숫자를 다시 문자열로 변환하여 반환
    } else {
      return 0;
    }
  };

  const viewModalOpen = (data) => {
    setViewModal(!viewModal);
    setDetailData(data);
  };

  const viewModalClose = (status) => {
    setViewModal(false);
    if (status === 'reload') {
      window.location.reload();
    } else {
      getTotalData();
      grade2TotalData();
      fetchData();
      getProductData();
    }
  };

  const productDetailOpen = (data) => {
    setProductModal(!productModal);
    setSelectProduct(data);
  };

  let decodeResult;

  switch (decodeS4()) {
    case '슈퍼관리자':
      decodeResult = (
        <div className="main_wrap">
          <div className="main_back">
            <div className="super_wrap">
              <div className="sub-box">
                <div className="sub-icon1"></div>
                <div>{returnTotalCost(totalCost)} 원</div>
                <div className="sub-txt">예상 총매출액</div>
              </div>
              <div className="sub-box">
                <div className="sub-icon9"></div>
                <div>{returnTotalCost(totalPrice)} 원</div>
                <div className="sub-txt">순이익</div>
              </div>
              <div className="sub-box">
                <div className="sub-icon3"></div>
                <div>{totalData} 개</div>
                <div className="sub-txt">총지점수</div>
              </div>
              <div className="sub-box">
                <div className="sub-icon6"></div>
                <div>{hopeData} 명</div>
                <div className="sub-txt">상담희망고객수</div>
              </div>
              <div className="sub-box">
                <div className="sub-icon8"></div>
                <div>{contractData} 명</div>
                <div className="sub-txt">계약고객수(청약고객수)</div>
              </div>
              <div className="sub-box">
                <div className="sub-icon5"></div>
                <div>{customerData} 명</div>
                <div className="sub-txt">고객수</div>
              </div>
              <div className="sub-box">
                <div className="sub-icon2"></div>
                <div>{Number(paidCommision || 0).toLocaleString()} 원</div>
                <div className="sub-txt">총 지급커미션합계</div>
              </div>
              <div className="sub-box">
                <div className="sub-icon2"></div>
                <div>{Number(UnPaidCommision || 0).toLocaleString()} 원</div>
                <div className="sub-txt">총 미지급예정커미션</div>
              </div>
              <div className="sub-box">
                <div className="sub-icon4"></div>
                <div>{managerData} 명</div>
                <div className="sub-txt">총영업자수</div>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    case '지점관리자':
      decodeResult = (
        <div className="main_wrap">
          <div className="main_back">
            <div className="super_wrap">
              {grade2Data.length > 0 &&
              grade2Data2.length > 0 &&
              grade2Data3.length > 0 &&
              grade2Data4.length > 0 &&
              grade2Data5.length > 0 ? (
                <Fragment>
                  <div className="sub-box">
                    <div className="sub-icon7"></div>
                    <div>{grade2Data[0].branch_type}</div>
                    <div className="sub-txt">지점종류</div>
                  </div>
                  <div className="sub-box">
                    <div className="sub-icon6"></div>
                    <div>{grade2Data2[0].customerCount}</div>
                    <div className="sub-txt">소속영업사원</div>
                  </div>
                  <div className="sub-box">
                    <div className="sub-icon3"></div>
                    <div>{grade2Data[0].company_name}</div>
                    <div className="sub-txt">회사명</div>
                  </div>
                  <div className="sub-box">
                    <div className="sub-icon8"></div>
                    <div>{grade2Data3[0].totalCount}</div>
                    <div className="sub-txt">가입고객현황</div>
                  </div>
                  <div className="sub-box">
                    <div className="sub-icon7"></div>
                    <div>{grade2Data[0].branch_name}</div>
                    <div className="sub-txt">지점명</div>
                  </div>
                  <div className="sub-box">
                    <div className="sub-icon5"></div>
                    <div>{grade2Data4[0].hopeCount}</div>
                    <div className="sub-txt">상담희망고객</div>
                  </div>
                  <div className="sub-box">
                    <div className="sub-icon4"></div>
                    <div>{grade2Data[0].owner_name}</div>
                    <div className="sub-txt">지점장</div>
                  </div>
                  <div className="sub-box">
                    <div className="sub-icon6"></div>
                    <div>{grade2Data5[0].contractCount}</div>
                    <div className="sub-txt">계약고객현황</div>
                  </div>
                  <div className="sub-box">
                    <div className="sub-icon1"></div>
                    <div>-</div>
                    <div className="sub-txt">지급완료커미션</div>
                  </div>
                </Fragment>
              ) : (
                <div>데이터가 없습니다.</div>
              )}
            </div>
          </div>
        </div>
      );
      break;
    case '영업사원':
      decodeResult = (
        <div className="main_wrap">
          <div className="main_back home">
            <div className="main_title_box blank">예약하기</div>
            <div className="main_sub_title">건강검진 예약을 신청합니다.</div>
            <div className="customer_btn_box">
              <div className="customer_btn" onClick={() => navigation('/reserv', { state: { status: '' } })}>
                <div className="reserv_logo"></div>
                <div className="reserv_text">예약 시작하기</div>
                <div className="arrow_logo"></div>
              </div>
            </div>

            <div className="main_title_box2 blank">링크 전송하기</div>
            <div className="main_sub_title msg">메신저로 링크를 전달합니다.</div>
            <div className="customer_btn_box2">
              <div className="customer_btn1" onClick={() => sendKakaoMessage()}>
                <div className="reserv_logo1"></div>
                <div className="reserv_text1">카카오톡으로 보내기</div>
                <div className="arrow_logo"></div>
              </div>
            </div>
            <div className="customer_btn_box2">
              <div className="customer_btn2" onClick={() => openModal()}>
                <div className="reserv_logo2"></div>
                <div className="reserv_text2">문자로 보내기</div>
                <div className="arrow_logo"></div>
              </div>
            </div>
            <div className="customer_btn_box2">
              <div className="customer_btn3">
                <div className="reserv_logo3"></div>
                <div className="reserv_text3">링크복사</div>
                {/* <div className="link_text">
                  https://www.wlcmanager.com:8443/self/{decodeS1()}
                </div> */}
                <div className="send-btn-wrap" onClick={handleCopyLink}>
                  <div className="send-btn">복사하기</div>
                </div>
              </div>
            </div>
            <Fragment>
              <div className="third-wraps">
                <div className="main_title_box blank">7일 이내 검진예약 고객명단</div>
                <div className="main_sub_title">총 {rows.length}건의 예약</div>
              </div>
              <div className="table_box home">
                {rows.length > 0 ? ( // rows 배열에 데이터가 있을 때
                  window.innerWidth < 600 ? (
                    <TableDefault rows={rows} columns={columnsForMobile} viewModalOpen={viewModalOpen} />
                  ) : (
                    <TableDefault rows={rows} columns={columns} viewModalOpen={viewModalOpen} />
                  )
                ) : (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      textAlign: 'center',
                      border: '1px solid #ccc',
                      padding: '100px',
                      fontSize: '18px',
                      background: 'white',
                    }}
                  >
                    데이터가 존재하지 않습니다.
                  </div>
                )}
              </div>
              <Tel />
            </Fragment>
          </div>
          {productModal && (
            <ProductDetailModal closeModal={productDetailOpen} modalData={selectProduct}></ProductDetailModal>
          )}
          {/* 영업사원 7일이내 데이터모달 */}
          {viewModal && <SevendaysViewModal closeModal={viewModalClose} detailData={detailData}></SevendaysViewModal>}
          {modal && <LinkPhoneModal closeModal={closeModal} link={link}></LinkPhoneModal>}
        </div>
      );
      break;
    case '병원관리자':
      decodeResult = (
        <div className="main_wrap">
          <div className="main_back">
            <div className="super_wrap">
              {/* <div className="sub-box">
                <div className="sub-icon1"></div>
                <div>{returnTotalCost(totalCost)} 원</div>
                <div className="sub-txt">총매출액</div>
              </div>
              <div className="sub-box">
                <div className="sub-icon4"></div>
                <div>{managerData} 명</div>
                <div className="sub-txt">총영업자수</div>
              </div>
              <div className="sub-box">
                <div className="sub-icon9"></div>
                <div>{returnTotalCost(totalPrice)} 원</div>
                <div className="sub-txt">순이익</div>
              </div> */}
              <div className="sub-box">
                <div className="sub-icon5"></div>
                <div>{hospitalTotalData} 명</div>
                <div className="sub-txt">총 고객수</div>
              </div>
              <div className="sub-box">
                <div className="sub-icon2"></div>
                <div>[ 준비중 ]</div>
                <div className="sub-txt">총커미션합계</div>
              </div>
              <div className="sub-box">
                <div className="sub-icon7"></div>
                <div>[ 준비중 ]</div>
                <div className="sub-txt">지급예정커미션</div>
              </div>
              <div className="sub-box">
                <div className="sub-icon6"></div>
                <div>{hospitalPendingData} 명</div>
                <div className="sub-txt">검진대기 고객수</div>
              </div>
              <div className="sub-box">
                <div className="sub-icon6"></div>
                <div>{hospitalCompletedData} 명</div>
                <div className="sub-txt">검진완료 고객수</div>
              </div>
              <div className="sub-box">
                <div className="sub-icon6"></div>
                <div>{hospitalCanceledData} 개</div>
                <div className="sub-txt">검진취소 고객수</div>
              </div>
              <div className="sub-box">
                <div className="sub-icon6"></div>
                <div>{hospitalInsuranceCheckData} 개</div>
                <div className="sub-txt">보험점검 고객수</div>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    default:
      decodeResult = null;
  }
  return <Fragment>{decodeResult}</Fragment>;
};

export default Home;

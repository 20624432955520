import React, { useEffect, useRef, useState } from 'react';
import TableDefault from '../Table/TableDefault';
import { useLocation, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import CommisionSelect from './CommisionSelect';
import moment from 'moment';

const CommissionList = () => {
  const selectRef = useRef(null);
  const { decodeS3, decodeS1 } = useAuth();
  const location = useLocation();
  const { defaultSelect } = location.state || {};
  const [paidCommissionData, setPaidCommissionData] = useState([]); // 지급커미션
  const [unpaidCommissionData, setUnpaidCommissionData] = useState([]); // 미지급 커미션
  const [tab, setTab] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const { grade } = location.state || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (defaultSelect) {
      setSearchData(defaultSelect);
    }
  }, [defaultSelect]);

  useEffect(() => {
    getMember();
  }, [tab, searchData]);

  console.log('지급커미션리스트', paidCommissionData);
  console.log('미지급커미션리스트', unpaidCommissionData);

  //지급, 미지급 커미션 총합 데이터
  const getTotalCommission = (data) => {
    return data.reduce((total, item) => {
      const commisionPrice = Number(item.commision_price) || 0;
      return total + commisionPrice;
    }, 0);
  };

  const getMember = async () => {
    const resultParams = { ...searchData }; // Include search conditions

    switch (grade) {
      case '슈퍼관리자':
        resultParams.grade = [1, 2, 3];
        resultParams.status = tab;
        break;
      case '지점관리자':
        resultParams.grade = [2, 3];
        resultParams.status = tab;
        resultParams.branch = decodeS3();
        break;
      case '영업사원':
        resultParams.grade = [3];
        resultParams.status = tab;
        resultParams.uid = decodeS1();
        break;
      default:
        return;
    }

    try {
      const res = await Axios.get('https://www.wlcmanager.com:8443/api/get/commision_list', {
        params: resultParams,
      });

      if (res.data.success) {
        const paidData = [];
        const unpaidData = [];

        res.data.data.forEach((data) => {
          const formattedData = {
            branch_idx: data.branch_idx,
            branch_type: data.branch_type,
            company_name: data.company_name,
            branch_name: data.branch_name,
            location: data.location,
            date: moment(data.date).format('YYYY.MM.DD'),
            idx: data.idx,
            number: data.number,
            uid: data.uid,
            p_key: data.p_key,
            h_key: data.h_key,
            commision_price: data.commision_price,
            result_date: data.result_date,
          };

          if (data.commision_yn === 'Y') {
            paidData.push(formattedData);
          } else if (data.commision_yn === 'N') {
            unpaidData.push(formattedData);
          }
        });

        setPaidCommissionData(paidData.map((item, index) => ({ ...item, id: index + 1 })));
        setUnpaidCommissionData(unpaidData.map((item, index) => ({ ...item, id: index + 1 })));
      } else {
        setPaidCommissionData([]);
        setUnpaidCommissionData([]);
        alert('검색 조건에 맞는 데이터가 없습니다.');
        selectRef.current?.clearSearch();
      }
    } catch (err) {
      console.error('데이터 호출 실패:', err);
    }
  };

  // 지급커미션
  const columns1 = [
    { field: 'id', headerName: 'No', flex: 0.5 },
    { field: 'uid', headerName: '병원-고객 고유키' },
    { field: 'branch_type', headerName: '지점종류' },
    { field: 'company_name', headerName: '회사명' },
    { field: 'branch_name', headerName: '지점명' },
    { field: 'h_key', headerName: '병원' },
    { field: 'p_key', headerName: '상품' },
    { field: 'number', headerName: '검진인원' },
    {
      field: 'commision_price',
      headerName: '지급완료커미션',
      valueFormatter: (params) => {
        const value = params.value; // 원본 값 가져오기
        if (value == null || isNaN(value)) {
          return ''; // 값이 없거나 숫자가 아니면 빈 문자열
        }
        return Number(value).toLocaleString(); // 천 단위 쉼표 추가
      },
    },
    //{ field: 'location', headerName: '지역' },
    { field: 'result_date', headerName: '검진확정일' },
  ];
  // 미지급
  const columns2 = [
    { field: 'id', headerName: 'No', flex: 0.5 },
    { field: 'uid', headerName: '병원-고객 고유키' },
    { field: 'branch_type', headerName: '지점종류' },
    { field: 'company_name', headerName: '회사명' },
    { field: 'branch_name', headerName: '지점명' },
    { field: 'h_key', headerName: '병원' },
    { field: 'p_key', headerName: '상품' },
    { field: 'number', headerName: '검진인원' },
    {
      field: 'commision_price',
      headerName: '지급예정커미션',
      valueFormatter: (params) => {
        const value = params.value; // 원본 값 가져오기
        if (value == null || isNaN(value)) {
          return ''; // 값이 없거나 숫자가 아니면 빈 문자열
        }
        return Number(value).toLocaleString(); // 천 단위 쉼표 추가
      },
    },
    //{ field: 'location', headerName: '지역' },
    { field: 'result_date', headerName: '검진확정일' },
    {
      field: 'action',
      headerName: '입금확인',
      renderCell: (params) => {
        const handleConfirm = async () => {
          const confirmResult = window.confirm('입금을 확정하시겠습니까?');
          if (!confirmResult) {
            return; // 사용자가 '아니오'를 선택한 경우 종료
          }

          try {
            // 서버에 데이터 업데이트 요청
            const res = await Axios.post('https://www.wlcmanager.com:8443/api/post/commision_yn', {
              uid: params.row.uid,
              idx: params.row.idx, // idx 값 추가
            });

            if (res.data.success) {
              alert(res.data.message); // 성공 메시지 출력
              getMember(); // 데이터 새로고침
            } else {
              alert(res.data.message); // 실패 메시지 출력
            }
          } catch (error) {
            console.error('Error updating commision_yn:', error);
            alert('서버와 통신 중 오류가 발생했습니다.'); // 서버 통신 오류 메시지
          }
        };

        return (
          <button
            onClick={handleConfirm}
            style={{
              padding: '5px 12px',
              backgroundColor: '#4caf50',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            확 인
          </button>
        );
      },
    },
  ];

  /* const moveDetailPage = (id) => {
    navigate(`/commission/${id}`);
  }; */

  const changeTab = (num) => {
    setTab(num);
    selectRef.current.clearSearch();
  };

  return (
    <div className="main_wrap">
      <div className="main_back">
        <div className="main_title_box">
          [ 커미션 관리 ]
          <div className="total_data_box">
            <div className="total_box">
              [지급커미션 총액] : {getTotalCommission(paidCommissionData).toLocaleString()} 원
            </div>
            <div className="total_box">
              [미지급커미션 총액] : {getTotalCommission(unpaidCommissionData).toLocaleString()} 원
            </div>
          </div>
        </div>
        <div className="board_list_wrap">
          <div className="list_area">
            <div className="search_box">
              <div className="search_select">
                <CommisionSelect ref={selectRef} setSearchData={setSearchData} defaultSelect={defaultSelect} />
              </div>
            </div>
            {grade !== '영업사원' && (
              <div className="tab_area">
                <div className="tab_back">
                  <div className={`tab_menu ${tab === 1 && 'active'}`} onClick={() => changeTab(1)}>
                    지급커미션
                  </div>
                  <div className={`tab_menu ${tab === 2 && 'active'}`} onClick={() => changeTab(2)}>
                    미지급
                  </div>
                </div>
              </div>
            )}
            <div className="table_box tab_list">
              {(tab === 1 && paidCommissionData.length === 0) || (tab === 2 && unpaidCommissionData.length === 0) ? (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    border: '1px solid #ccc',
                    padding: '100px',
                    fontSize: '18px',
                    background: 'white',
                  }}
                >
                  데이터가 존재하지 않습니다.
                </div>
              ) : (
                <TableDefault
                  rows={tab === 1 ? paidCommissionData : unpaidCommissionData}
                  columns={tab === 1 ? columns1 : columns2}
                  viewModalOpen={() => {}}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommissionList;

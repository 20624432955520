import React, { useState, Fragment, useEffect } from 'react';
import { useReservContext } from '../../contexts/ReservContext';
import { useLocation, useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import Tel from '../Common/Tel';

const SelectDate2 = () => {
  const { setDates1, setDates2, dates1, dates2, hospitalOriginKey } = useReservContext();
  const [date1, setDate1] = useState(new Date() || dates1);
  const [date2, setDate2] = useState(new Date() || dates2);
  const [openCalendarNum, setOpenCalendarNum] = useState(0);
  const navigation = useNavigate();
  const location = useLocation();

  const handleBack = () => {
    navigation(-1);
  };

  useEffect(() => {
    //병원 h_key가 없으면 처음 선택화면으로 이동
    if (hospitalOriginKey === '') {
      navigation('/reserv');
    }
  }, []);

  const moveNext = () => {
    // 보험 상담일 선택
    if (!date1 || !date2) {
      alert('모든 필드를 입력해주세요.');
      return;
    }

    // 상담일 및 시간 설정
    setDates1(moment(date1).format('YYYY.MM.DD'));
    setDates2(moment(date2).format('YYYY.MM.DD'));

    // 다음 페이지로 이동
    navigation('/reserv/customer');
  };

  const handleDateChange = (date, calendarNum) => {
    if (calendarNum === 1) {
      setDate1(date);
    } else if (calendarNum === 2) {
      setDate2(date);
    }
    setOpenCalendarNum(0); // 달력 닫기
  };

  return (
    <div className="reserv_wrap">
      <div className="back_btn_box">
        <div className="back_btn" onClick={handleBack}>
          뒤로 이동
        </div>
      </div>
      <div className="reserv_back">
        <div className="reserv_top_box">
          <div className="reserv_title">검진일 선택</div>
          <div className="reserv_title sub">희망하시는 일자를 선택하세요.</div>
        </div>
        <div className="reserv_bottom_box">
          <div className="reserv_contents_box">
            <Fragment>
              <div className="reserv_input_box calendar">
                <input
                  className="reserv_input calendar"
                  placeholder="희망검진일1"
                  value={moment(date1).format('YYYY.MM.DD')}
                  readOnly
                  onClick={() => setOpenCalendarNum(1)}
                />
                {openCalendarNum === 1 && (
                  <Calendar
                    className="reserv_calendar"
                    onChange={(date) => handleDateChange(date, 1)}
                    value={date1}
                    minDate={new Date()}
                  />
                )}
              </div>
              <div className="reserv_input_box calendar">
                <input
                  className="reserv_input calendar"
                  placeholder="희망검진일2"
                  value={moment(date2).format('YYYY.MM.DD')}
                  readOnly
                  onClick={() => setOpenCalendarNum(2)}
                />
                {openCalendarNum === 2 && (
                  <Calendar
                    className="reserv_calendar"
                    onChange={(date) => handleDateChange(date, 2)}
                    value={date2}
                    minDate={new Date()}
                  />
                )}
              </div>
            </Fragment>
            <div className="reserv_btn_box">
              <div className="reserv_btn" onClick={moveNext}>
                다음 페이지로
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tel />
    </div>
  );
};

export default SelectDate2;

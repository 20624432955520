import React from 'react';

const BranchCustomerModal = () => {
  return (
    <div>
      <div>해당지점 총 고객수 상세모달</div>
    </div>
  );
};

export default BranchCustomerModal;

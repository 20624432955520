import React from 'react';

const NextInfo = ({ onClose }) => {
  return (
    <div className="next-info-overlay" onClick={onClose}>
      <section className="next-info-modal" onClick={(e) => e.stopPropagation()}>
        <header className="next-info-header">
          <h1>보험 점검 후 검진이란?</h1>
          <h2>검진 후 발견되는 질병에 준비가 되어 있으신가요?</h2>
          <button className="next-info-close-btn" onClick={onClose} aria-label="Close">
            &times;
          </button>
        </header>
        <article className="next-info-body">
          <p>
            보험 점검 후 검진을 통해 <strong> 검진 비용을 낮추고</strong>, <strong> 주요 질병에 미리 대비</strong>
            하세요!
          </p>
          <ul>
            <li>
              <strong>💡 왜 보험 점검이 필요한가요?</strong>
            </li>
            <li>· 현재 가입된 보험이 여러분의 필요를 충족하고 있는지 확인하세요.</li>
            <li>· 추가 보장이 필요한 경우, 더 합리적인 선택으로 보완할 수 있습니다.</li>
          </ul>
          <p>
            지금 바로 보험과 건강검진을 함께 챙겨보세요! 문의사항이 있다면 언제든지{''}
            <a>010-9915-5706</a>으로 연락주세요. 😊
          </p>
        </article>
      </section>
    </div>
  );
};

export default NextInfo;

import React, { useEffect, useState, useImperativeHandle, useRef } from 'react';
import { useBranchContext } from '../../contexts/BranchContext';
import Axios from 'axios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';

const CustomerSelect = (props, ref) => {
  const { typeGroup, companyGroup, branchGroup, setContextType, setContextCompany } = useBranchContext();
  const [type, setType] = useState('');
  const [company, setCompany] = useState('');
  const [branchName, setBranchName] = useState('');
  const [branchIdx, setBranchIdx] = useState('');
  const [product, setProduct] = useState('');
  const [hospital, setHospital] = useState('');
  const [productList, setProductList] = useState([]);
  const [hospitalList, setHospitalList] = useState([]);
  const [searchOption, setSearchOption] = useState('manager');
  const [searchInput, setSearchInput] = useState('');
  const [level, setLevel] = useState('');
  const [startDate, setStartDate] = useState(null); // 가입일1
  const [endDate, setEndDate] = useState(null); // 가입일2
  const [openCalendar, setOpenCalendar] = useState({
    start: false,
    end: false,
  });

  useImperativeHandle(ref, () => ({
    clearSearch,
  }));

  useEffect(() => {
    if (props.defaultSelect) {
      setSearchOption('manager');
      setSearchInput(props.defaultSelect.manager);
    }
  }, [props.defaultSelect]);

  useEffect(() => {
    setContextType(type);
  }, [type]);

  useEffect(() => {
    if (props.level) {
      setLevel(props.level);
    }
  }, [props.level]);

  useEffect(() => {
    setContextCompany(company);
  }, [company]);

  useEffect(() => {
    getProduct();
    getHospital();
  }, []);

  const getProduct = async () => {
    try {
      const response = await Axios.get('https://www.wlcmanager.com:8443/api/get/reserv/product_list');
      setProductList(response.data.data || []); // 데이터가 없을 경우 빈 배열로 설정
    } catch (error) {
      console.error('Error fetching product list:', error);
      setProductList([]); // 오류 발생 시 빈 배열로 초기화
    }
  };

  const getHospital = async () => {
    try {
      const response = await Axios.get('https://www.wlcmanager.com:8443/api/get/reserv/hospital_list');
      setHospitalList(response.data.data || []); // 데이터가 없을 경우 빈 배열로 설정
    } catch (error) {
      console.error('Error fetching hospital list:', error);
      setHospitalList([]); // 오류 발생 시 빈 배열로 초기화
    }
  };

  const selectBranch = (num) => {
    setBranchIdx(num);
    const selectedBranch = branchGroup.find((data) => data.branch_idx === num);
    if (selectedBranch) {
      setBranchName(selectedBranch.branch_name);
    }
  };

  const selectType = (data) => {
    setType(data);
    setCompany('');
    setBranchIdx('');
    setBranchName('');
  };

  const resetData = () => {
    setProduct('');
    setHospital('');
    setSearchOption('manager');
    setSearchInput('');
  };

  const setDateRange = (date, isStart) => {
    if (isStart) {
      setStartDate(date);
      setEndDate(null); // 또는 필요에 따라 endDate를 설정합니다.
    } else {
      setEndDate(date);
    }
    setOpenCalendar({ start: false, end: false }); // 날짜 선택 후 달력 닫기
  };

  const toggleCalendar = (type) => {
    setOpenCalendar({
      start: type === 'start' ? !openCalendar.start : false,
      end: type === 'end' ? !openCalendar.end : false,
    });
  };

  const handleSearch = () => {
    if (props.setSearchData) {
      const searchData = {
        branch_type: type,
        company_name: company,
        branch_name: branchName,
        product: product,
        hospital: hospital,
        start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
        end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
      };

      // 검색 옵션에 따라 검색어를 추가
      searchData[searchOption] = searchInput;

      // 콘솔에 searchData 출력
      //console.log("Search Data:", searchData);

      // 검색 데이터를 상위 컴포넌트로 전달
      props.setSearchData(searchData);
    }
  };

  const clearSearch = () => {
    if (props.setSearchData) {
      props.setSearchData([]);
      selectType('');
      resetData();
      setStartDate(null);
      setEndDate(null);
    }
  };

  return (
    <div className="list_select_area">
      <div className="search_select">
        {level !== '지점관리자' && (
          <div className="search_input_container">
            <select className="list_select" value={type} onChange={(e) => selectType(e.target.value)}>
              <option value="">지점종류</option>
              {typeGroup.map((type, index) => {
                return (
                  <option key={index} value={type}>
                    {type}
                  </option>
                );
              })}
            </select>
            <select className="list_select" value={company} onChange={(e) => setCompany(e.target.value)}>
              <option value="">회사명</option>
              {companyGroup.map((data, index) => {
                return (
                  <option key={index} value={data}>
                    {data}
                  </option>
                );
              })}
            </select>
            <select className="list_select" value={branchIdx} onChange={(e) => selectBranch(e.target.value)}>
              <option value="">지점명</option>
              {branchGroup.map((data, index) => {
                return (
                  <option key={index} value={data.branch_idx}>
                    {data.branch_name}
                  </option>
                );
              })}
            </select>
            <select className="list_select" value={product} onChange={(e) => setProduct(e.target.value)}>
              <option value="">상품명</option>
              {productList.map((data, index) => {
                return (
                  <option key={index} value={data.product_1}>
                    {data.product_1}
                  </option>
                );
              })}
            </select>
            <select className="list_select" value={hospital} onChange={(e) => setHospital(e.target.value)}>
              <option value="">병원</option>
              {hospitalList.map((data, index) => {
                return (
                  <option key={index} value={data.name}>
                    {data.name}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        <div className="search_input_container ">
          <select className="list_select" value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
            <option value="manager">영업사원명</option>
            {level !== '지점관리자' && <option value="contractor_name">예약자명</option>}
            <option value="name">검진자명</option>
          </select>
          <div className="search_input">
            <input
              className="list_input"
              placeholder="검색어를 입력하세요"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            ></input>
            <div className="list_search" onClick={() => handleSearch()}>
              검색
            </div>
            <div className="list_search reset_btn" onClick={() => clearSearch()}>
              초기화
            </div>
          </div>
        </div>
      </div>
      <div className="custom-wrap">
        <div className="custom-search">
          <input
            className="table_input"
            type="text"
            placeholder="가입일1"
            value={startDate ? moment(startDate).format('YYYY.MM.DD') : ''}
            onClick={() => toggleCalendar('start')}
            readOnly
          />
          {openCalendar.start && (
            <Calendar
              className="modal_calendar"
              onChange={(date) => setDateRange(date, true)}
              value={startDate}
              formatDay={(locale, date) => moment(date).format('DD')}
              minDate={moment().toDate()}
              calendarType="gregory"
            />
          )}
        </div>
        &nbsp;~&nbsp;
        <div className="custom-search">
          <input
            className="table_input"
            type="text"
            placeholder="가입일2"
            value={endDate ? moment(endDate).format('YYYY.MM.DD') : ''}
            onClick={() => toggleCalendar('end')}
            readOnly
          />
          {openCalendar.end && (
            <Calendar
              className="modal_calendar"
              onChange={(date) => setDateRange(date, false)}
              value={endDate}
              formatDay={(locale, date) => moment(date).format('DD')}
              minDate={moment().toDate()}
              calendarType="gregory"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default React.forwardRef(CustomerSelect);

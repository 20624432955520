import React from 'react';

const Tel = () => {
  return (
    <div className="tel-wrap">
      <a href="tel:01099155706" aria-label="전화 걸기">
        <div className="icon-wrap">
          <div className="tel-icon"></div>
          <p>문의전화</p>
        </div>
      </a>
    </div>
  );
};

export default Tel;

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReservContext } from '../../contexts/ReservContext';
import Tel from '../Common/Tel';

const SelfSearchCategory = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const selfUrl = location.state?.selfUrl || '';

  const movePage = (path, status) => {
    navigation(`${selfUrl}${path}`, {
      state: { status: status, selfUrl: selfUrl },
    });
  };

  return (
    <div className="reserv_wrap">
      <div className="back_btn_box">
        <div className="back_btn" onClick={() => navigation(-1)}>
          뒤로 이동
        </div>
      </div>
      <div className="reserv_back main">
        <div className="reserv_top_box">
          <div className="reserv_title">카테고리선택</div>
          <div className="reserv_title sub">원하시는 카테고리를 확인하고 선택합니다.</div>
        </div>
        {/* <div className="customer_btn_box3">
          <div className="customer_btn_cate1" onClick={() => movePage('/product', '1')}>
            <div className="reserv_logo_cate1"></div>
            <div className="reserv_text_cate1">국가검진</div>
            <div className="arrow_logo_cate1"></div>
          </div>
        </div> */}
        <div className="customer_btn_box3">
          <div className="customer_btn_cate2" onClick={() => movePage('/product', '2')}>
            <div className="reserv_logo_cate2"></div>
            <div className="reserv_text_cate2">일반종합검진</div>
            <div className="arrow_logo_cate2"></div>
          </div>
        </div>
        <div className="customer_btn_box3">
          <div className="customer_btn_cate4" onClick={() => movePage('/product', '3')}>
            <div className="reserv_logo_cate4"></div>
            <div className="reserv_text_cate4">신부 패키지(줄기세포)</div>
            <div className="arrow_logo_cate4"></div>
          </div>
        </div>
        <div className="customer_btn_box3">
          <div className="customer_btn_cate5" onClick={() => movePage('/product', '4')}>
            <div className="reserv_logo_cate5"></div>
            <div className="reserv_text_cate5">효도상품(줄기세포)</div>
            <div className="arrow_logo_cate5"></div>
          </div>
        </div>
      </div>
      <Tel />
    </div>
  );
};

export default SelfSearchCategory;

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Axios from 'axios';
import dayjs from 'dayjs';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const HospitalCustomerViewModal = (props) => {
  const [detailData, setDetailData] = useState([]); //리스트에서 받아온 props 데이터
  const [memberData, setMemberData] = useState([]); //getDetail 함수로 불러온 영업지점, 상품명 데이터
  const [product, setProduct] = useState(''); //상품명
  const [payStatus, setPayStatus] = useState('N'); //입금유무 (병원관리자에서는 검진완료이면(Y), 검진취소, 검진대기면(N), 보험점검 별개)
  const [result_date, setResultDate] = useState(''); //검진확정일
  const [start_time, setStartTime] = useState(''); //검진시간
  const [status, setStatus] = useState(props.hospitalArrayData.status); //검진상태
  const [startTime, setStart] = useState(null); //검진시간 선택 state
  const [memo, setMemo] = useState(''); //비고
  const [selectedStatus, setSelectedStatus] = useState(null); //검진여부 라디오버튼
  const [openCalendar, setOpenCalendar] = useState(false); //달력오픈상태 확인
  const [resultCalendar, setResultCalendar] = useState(''); //검진확정일 달력데이터

  useEffect(() => {
    if (!props.hospitalArrayData || props.hospitalArrayData.length === 0) {
      console.error('Invalid or empty hospitalArrayData:', props.hospitalArrayData);
      return;
    }

    const initialData = props.hospitalArrayData[0];
    setDetailData([initialData]); // detailData에 초기 데이터 저장
    setResultDate(initialData.result_date ? moment(initialData.result_date).format('YYYY.MM.DD') : '');
    setResultCalendar(initialData.result_date ? new Date(initialData.result_date) : null);
    setStartTime(initialData.start_time || '');
    setStart(initialData.start_time ? dayjs(initialData.start_time, 'HH:mm') : null);
  }, [props.hospitalArrayData]);

  useEffect(() => {
    setDetailValue();
  }, [memberData]);

  useEffect(() => {
    if (props.hospitalArrayData && Array.isArray(props.hospitalArrayData)) {
      getDetail(); // 컴포넌트 초기 렌더링 시 데이터 가져오기
    }
  }, [props.hospitalArrayData]);

  //해당 고객 지점명, 상품명 호출
  const getDetail = async () => {
    try {
      // hospitalArrayData에서 첫 번째 항목의 idx를 가져옴
      const targetIdx = props.hospitalArrayData?.[0]?.idx;

      if (!targetIdx) {
        console.error('No valid idx found in hospitalArrayData');
        return;
      }

      const params = { idx: targetIdx };
      console.log('Request params:', params); // 요청 params 확인

      const response = await Axios.get('https://www.wlcmanager.com:8443/api/get/customer_detail', { params });

      const allData = response.data.data;
      if (allData.length > 0) {
        setMemberData(allData[0]); // memberData 업데이트
        setDetailValue(allData[0]); // 가져온 데이터를 setDetailValue로 초기화
      }

      console.log('allData:', allData); // 응답 데이터 확인
    } catch (error) {
      console.error('Error fetching list:', error);
    }
  };

  const setDetailValue = () => {
    setStart(dayjs(memberData.start_time, 'HH:mm'));
    // setConsulting(dayjs(memberData.consulting_time, 'HH:mm'));
    setProduct(memberData.p_key);
    setResultDate(memberData.result_date);
    setMemo(memberData.memo);
    setPayStatus(memberData.pay_status);
  };

  //라디오버튼
  useEffect(() => {
    if (detailData.length > 0) {
      setSelectedStatus(detailData[0]?.status); // 초기값 설정
    }
  }, [detailData]);
  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value); // 사용자 클릭으로 상태 업데이트
  };

  //검진확정일
  const calendarStatus = () => {
    setOpenCalendar(!openCalendar);
  };
  const setFormatDate = (date) => {
    const momentDate = moment(date).format('YYYY.MM.DD');
    setResultDate(momentDate);
    setResultCalendar(date);
    setOpenCalendar(false);
  };

  //검진시간 핸들러
  const clockHandle = (newValue) => {
    const formattedTime = dayjs(newValue).format('HH:mm');
    console.log(formattedTime);
    setStartTime(formattedTime);
    setStart(newValue);
  };

  //모달닫기
  const clearModal = () => {
    props.closeModal();
  };

  const handleSubmit = async () => {
    if (!result_date && !start_time && !selectedStatus && !memo) {
      alert('수정할 데이터를 입력해주세요.');
      return;
    }

    const confirmUpdate = window.confirm('변경된 정보를 저장하시겠습니까?');
    if (!confirmUpdate) {
      return;
    }

    // UID 기반으로 요청 데이터 구성
    const paramsArray = {
      uid: detailData[0]?.uid, // UID 전송
      result_date: result_date || null, // 검진 확정일
      start_time: start_time || null, // 검진 시간
      status: selectedStatus || null, // 검진 상태
      memo: memo || null, // 비고
    };

    try {
      const response = await Axios.post('https://www.wlcmanager.com:8443/api/post/hospital_customer_edit', paramsArray);
      alert(response.data.message || '수정이 완료되었습니다.');
      props.closeModal();
    } catch (error) {
      console.error('Error during update:', error);
      alert('수정 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="modal_wrap">
      <div className="modal_back">
        <div className="modal_box">
          <div className="modal_title_box">
            <div className="modal_title">예약고객 상세</div>
            <div>
              ( <span className="redsec">*</span> 병원관리자 수정가능항목 )
            </div>
            <div className="modal_close_btn" onClick={clearModal}>
              X
            </div>
          </div>
          <div className="table_box">
            {detailData.length > 0 ? (
              detailData
                .filter((data) => data.idx === props.hospitalArrayData?.[0]?.idx)
                .map((data, index) => (
                  <React.Fragment key={index}>
                    <div className="table_row">
                      {/* 검진자, 예약자, 검진 인원 */}
                      <div className="table_section half">
                        <div className="table_title">검진자</div>
                        <div className="table_contents w100">
                          <div className="table_inner_text">{data.name || '-'}</div>
                        </div>
                      </div>
                      <div className="table_section half">
                        <div className="table_title">예약자</div>
                        <div className="table_contents w100">
                          <div className="table_inner_text">{data.contractor_name || '-'}</div>
                        </div>
                      </div>
                    </div>

                    <div className="table_row">
                      <div className="table_section half">
                        <div className="table_title">검진자 연락처</div>
                        <div className="table_contents w100">
                          <div className="table_inner_text">{data.phone_2 || '-'}</div>
                        </div>
                      </div>
                      <div className="table_section half">
                        <div className="table_title">예약자 연락처</div>
                        <div className="table_contents w100">
                          <div className="table_inner_text">{data.phone || '-'}</div>
                        </div>
                      </div>
                    </div>

                    <div className="table_row">
                      <div className="table_section half">
                        <div className="table_title">검진인원</div>
                        <div className="table_contents w100">
                          <div className="table_inner_text">{data.number || '-'}</div>
                        </div>
                      </div>
                      <div className="table_section half">
                        <div className="table_title">검진자 주소</div>
                        <div className="table_contents w100">
                          <div className="table_inner_text">{data.address || '-'}</div>
                        </div>
                      </div>
                    </div>

                    <div className="table_row">
                      <div className="table_section half">
                        <div className="table_title">영업지점명</div>
                        <div className="table_contents w100">
                          <div className="table_inner_text">
                            [ {memberData.company} {memberData.branch} ]
                          </div>
                        </div>
                      </div>
                      <div className="table_section half">
                        <div className="table_title">상품명</div>
                        <div className="table_contents w100">
                          <div className="table_inner_text">[ {memberData.product || '-'} ]</div>
                        </div>
                      </div>
                    </div>

                    <div className="table_row">
                      <div className="table_section half">
                        <div className="table_title">검진희망일(1)</div>
                        <div className="table_contents w100">
                          <div className="table_inner_text">
                            {data.hope_date_1 ? moment(data.hope_date_1).format('YYYY-MM-DD') : '-'}
                          </div>
                        </div>
                      </div>
                      <div className="table_section half">
                        <div className="table_title">검진희망일(2)</div>
                        <div className="table_contents w100">
                          <div className="table_inner_text">
                            {data.hope_date_2 ? moment(data.hope_date_2).format('YYYY-MM-DD') : '-'}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="table_row">
                      <div className="table_section half">
                        <div className="table_title">입금금액</div>
                        <div className="table_contents w100">
                          <div className="table_inner_text">
                            {' '}
                            {data.cost && data.number ? (data.cost * data.number).toLocaleString() : '-'}
                          </div>
                        </div>
                      </div>
                      <div className="table_section half">
                        <div className="table_title">마케팅동의여부</div>
                        <div className="table_contents w100">
                          <div className="table_inner_text">{data.marketing_terms || '-'}</div>
                        </div>
                      </div>
                    </div>

                    <div className="table_row">
                      <div className="table_section half">
                        <div className="table_title">
                          <span className="redsec">*</span> 검진확정일
                        </div>
                        <div className="table_contents w100 calendar">
                          <input
                            className="table_input w100"
                            type="text"
                            id="title"
                            placeholder="확정일 입력해주세요."
                            value={result_date}
                            onClick={() => calendarStatus()}
                            readOnly
                          />
                          {openCalendar && (
                            <Calendar
                              className="modal_calendar hospital"
                              onChange={(e) => setFormatDate(e)}
                              value={resultCalendar}
                              formatDay={(locale, date) => moment(date).format('DD')}
                              minDate={moment().toDate()}
                              calendarType="gregory"
                            />
                          )}
                        </div>
                      </div>
                      <div className="table_section half">
                        <div className="table_title">
                          <span className="redsec">*</span> 검진시간
                        </div>
                        <div className="table_contents w100">
                          <div className="clock_box">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker value={startTime} onChange={clockHandle} />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="table_row">
                      <div className="table_section full">
                        <div className="table_title">
                          <span className="redsec">*</span> 검진상태
                        </div>
                        <div className="table_contents w100">
                          <div className="table_radio">
                            <label>
                              <input
                                type="radio"
                                name="status"
                                value="1"
                                checked={selectedStatus === '1'}
                                onChange={handleStatusChange}
                              />
                              검진완료
                            </label>
                          </div>
                          <div className="table_radio">
                            <label>
                              <input
                                type="radio"
                                name="status"
                                value="2"
                                checked={selectedStatus === '2'}
                                onChange={handleStatusChange}
                              />
                              검진취소
                            </label>
                          </div>
                          <div className="table_radio">
                            <label>
                              <input
                                type="radio"
                                name="status"
                                value="3"
                                checked={selectedStatus === '3'}
                                onChange={handleStatusChange}
                              />
                              검진대기
                            </label>
                          </div>
                          <div className="table_radio">
                            <label>
                              <input
                                type="radio"
                                name="status"
                                value="4"
                                checked={selectedStatus === '4'}
                                onChange={handleStatusChange}
                              />
                              보험점검
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="table_row">
                      <div className="table_section full">
                        <div className="table_title" style={{ height: '150px' }}>
                          <span className="redsec">*</span> 비고
                        </div>
                        <div className="table_contents w100">
                          <textarea
                            className="table_textarea"
                            value={memo}
                            onChange={(e) => setMemo(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))
            ) : (
              <div style={{ textAlign: 'center', padding: '20px', fontSize: '16px' }}>데이터가 존재하지 않습니다.</div>
            )}
          </div>
          <div className="modal_footer_box">
            <div className="modal_btn" onClick={() => handleSubmit()}>
              수정
            </div>
            <div className="modal_btn close" onClick={clearModal}>
              닫기
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HospitalCustomerViewModal;

import React, { useEffect, useState, useImperativeHandle, useRef } from 'react';
import Axios from 'axios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';

const HospitalCustomerSelect = (props, ref) => {
  const [searchOption, setSearchOption] = useState('name'); // 검색 옵션 (이름, 연락처)
  const [searchInput, setSearchInput] = useState(''); // 검색 입력값
  const [startHopeDate, setStartHopeDate] = useState(null); // 검진희망일1
  const [endHopeDate, setEndHopeDate] = useState(null); // 검진희망일2
  const [confirmDate, setConfirmDate] = useState(null); // 검진확정일
  const [openCalendar, setOpenCalendar] = useState({
    hopeStart: false,
    hopeEnd: false,
    confirm: false,
  });

  useImperativeHandle(ref, () => ({
    clearSearch,
  }));

  const setDateRange = (date, type) => {
    if (type === 'hopeStart') {
      setStartHopeDate(date);
    } else if (type === 'hopeEnd') {
      setEndHopeDate(date);
    } else if (type === 'confirm') {
      setConfirmDate(date);
    }
    setOpenCalendar({ ...openCalendar, [type]: false }); // 선택 후 달력 닫기
  };

  const toggleCalendar = (type) => {
    setOpenCalendar({
      hopeStart: type === 'hopeStart' ? !openCalendar.hopeStart : false,
      hopeEnd: type === 'hopeEnd' ? !openCalendar.hopeEnd : false,
      confirm: type === 'confirm' ? !openCalendar.confirm : false,
    });
  };

  const handleSearch = () => {
    if (props.setSearchData) {
      const searchData = {
        hope_date_1: startHopeDate ? moment(startHopeDate).format('YYYY.MM.DD') : '',
        hope_date_2: endHopeDate ? moment(endHopeDate).format('YYYY.MM.DD') : '',
        result_date: confirmDate ? moment(confirmDate).format('YYYY.MM.DD') : '',
      };

      // 이름과 연락처의 검색 조건 추가
      if (searchOption === 'name') {
        searchData.contractor_name = searchInput;
        searchData.name = searchInput;
      } else if (searchOption === 'phone') {
        searchData.phone = searchInput;
        searchData.phone_2 = searchInput;
      }

      console.log('검색 데이터:', searchData); // 검색 데이터를 콘솔에 출력
      props.setSearchData(searchData); // 검색 데이터를 상위 컴포넌트로 전달
    }
  };

  const clearSearch = () => {
    if (props.setSearchData) {
      props.setSearchData({});
      setSearchInput('');
      setStartHopeDate(null);
      setEndHopeDate(null);
      setConfirmDate(null);
    }
  };

  return (
    <div className="list_select_area">
      <div className="search_select">
        <div className="search_input_container">
          <div className="custom-wrap">
            <div className="custom-search">
              <input
                className="table_input"
                type="text"
                placeholder="검진희망일1"
                value={startHopeDate ? moment(startHopeDate).format('YYYY.MM.DD') : ''}
                onClick={() => toggleCalendar('hopeStart')}
                readOnly
              />
              {openCalendar.hopeStart && (
                <Calendar
                  className="modal_calendar hospital"
                  onChange={(date) => setDateRange(date, 'hopeStart')}
                  value={startHopeDate}
                  formatDay={(locale, date) => moment(date).format('DD')}
                  calendarType="gregory"
                />
              )}
            </div>
            &nbsp;&nbsp;
            <div className="custom-search">
              <input
                className="table_input"
                type="text"
                placeholder="검진희망일2"
                value={endHopeDate ? moment(endHopeDate).format('YYYY.MM.DD') : ''}
                onClick={() => toggleCalendar('hopeEnd')}
                readOnly
              />
              {openCalendar.hopeEnd && (
                <Calendar
                  className="modal_calendar hospital"
                  onChange={(date) => setDateRange(date, 'hopeEnd')}
                  value={endHopeDate}
                  formatDay={(locale, date) => moment(date).format('DD')}
                  calendarType="gregory"
                />
              )}
            </div>
            &nbsp;&nbsp;
            <div className="custom-search">
              <input
                className="table_input"
                type="text"
                placeholder="검진확정일"
                value={confirmDate ? moment(confirmDate).format('YYYY.MM.DD') : ''}
                onClick={() => toggleCalendar('confirm')}
                readOnly
              />
              {openCalendar.confirm && (
                <Calendar
                  className="modal_calendar hospital"
                  onChange={(date) => setDateRange(date, 'confirm')}
                  value={confirmDate}
                  formatDay={(locale, date) => moment(date).format('DD')}
                  calendarType="gregory"
                />
              )}
            </div>
            &nbsp;&nbsp;
            <select className="list_select" value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
              <option value="name">이름</option>
              <option value="phone">연락처</option>
            </select>
            <div className="search_input">
              <input
                className="list_input"
                placeholder="검색어를 입력하세요"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              ></input>
              <div className="list_search" onClick={handleSearch}>
                검색
              </div>
              <div className="list_search reset_btn" onClick={clearSearch}>
                초기화
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.forwardRef(HospitalCustomerSelect);

import React from 'react';

const BranchMosqueModal = () => {
  return (
    <div>
      <div>해당지점 사원 상세모달</div>
    </div>
  );
};

export default BranchMosqueModal;
